import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, useHistory } from 'react-router-dom';
import {
    Avatar,
    Button,
    Card,
    CardHeader,
    Container,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    TextField,
    Typography,
    Divider,
} from '@material-ui/core';
import logo from './logo.svg';
import followInstructor from './saatjaga.svg';
import betweenLines from './kollased_jooned.svg';
import wearVest from './ohutusvest_kiiver.svg';
import wearHeadphones from './myra.svg';
import noPhotos from './pildistamise_keeld.svg';
import noTouch from './ohutu_kaugus.svg';
import noSmoking from './suitsetamise_keeld.svg';
import vehicle from './anna_teed_s6idukile.svg';
import medic from './esmaabi.svg';
import exit from './evakueeru.svg';
import cctv from './videovalve.svg';
import disclosure from './mitteavalik_teave.svg';

const config = {
    apiKey: 'AIzaSyCrTv4WINXuC9H9y1ax4tPKY6ydXltp2Z0',
    authDomain: 'clevervisit-prod.firebaseapp.com',
    databaseURL: 'https://clevervisit-prod.firebaseio.com',
};

firebase.initializeApp(config);

const signIn = () => {
    const authProvider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(authProvider);
};

const signOut = () => {
    return firebase.auth().signOut();
};

const database = firebase.database();

const visitsRef = database.ref('visits');

const rules = [
    {
        en: 'Always follow your host and safety signs',
        et: 'Liigu alati koos saatjaga ja jälgi ohutusmärke ',
        image: followInstructor,
    },
    {
        en: 'Always walk along the walkway marked with a yellow line',
        et: 'Liigu kollase joonega märgitud liikumisteel',
        image: betweenLines,
    },
    {
        en: 'Wear a safety vest and, if necessary, a safety helmet in work zones',
        et: 'Kanna ohutus\u00ADvesti, vajadusel töö\u00ADtsoonides kaitse\u00ADkiivrit',
        image: wearVest,
    },
    {
        en: 'In case of loud noise, use hearing protection devices (earplugs)',
        et: 'Valju müra korral kasuta kuulmis\u00ADkaitse\u00ADvahendeid (kõrvatropid)',
        image: wearHeadphones,
    },
    {
        en: 'Photography and filming are only allowed with the permission of the host',
        et: 'Pildistamine ja filmimine vaid võõrustaja loal',
        image: noPhotos,
    },
    {
        en: 'Keep a safe distance from the machines and other equipment',
        et: 'Säilita ohutu kaugus masinatest ja muudest seadmetest',
        image: noTouch,
    },
    {
        en: 'Smoking is not allowed indoors. It is only permitted in designated areas outside',
        et: 'Suitsetamine on lubatud ainult selleks ettenähtud kohas',
        image: noSmoking,
    },
    {
        en: 'Stop and give way to vehicles',
        et: 'Peatu ja anna teed sõidukile',
        image: vehicle,
    },
    {
        en: 'In case of a fire, evacuate the building by following the signs',
        et: 'Tulekahju korral evakueeru hoonest jälgides märgistust',
        image: exit,
    },
    {
        en: 'You can find the locations of the first aid kits by the markings',
        et: 'Esmaabi\u00ADvahendite asukohad leiad märgistuse järgi',
        image: medic,
    },
    {
        en: "There is video surveillance on Cleveron's premises",
        et: 'Cleveron AS territooriumil ja siseruumides on videovalve',
        image: cctv,
    },
    {
        en: 'Keep non-public information you have obtained during the visit confidential',
        et: 'Hoia saladuses külastuse käigus teatavaks saanud Cleveroni AS-i mitteavalikku teavet',
        image: disclosure,
    },
];

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: '#051D77',
        color: '#FFFFFF',
        margin: theme.spacing(2),
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'center',
    },
    divider: {
        width: '100%',
    },
    dividerFullWidth: {
        margin: theme.spacing(0, 1),
        width: '100%',
    },
    extendedWidth: {
        margin: theme.spacing(0, -2),
        width: `calc(100% + ${theme.spacing(2 * 2)}px)`,
    },
    fillArea: {
        height: '100%',
        width: '100%',
    },
    logo: {
        maxWidth: '84%',
        padding: '4% 0',
        width: 300,
    },
    root: {
        borderRadius: 0,
        padding: theme.spacing(2, 4),
    },
    rule: {
        height: '100%',
        maxHeight: 130,
        minHeight: 90,
        padding: 0,
    },
    ruleImage: {
        height: 80,
        marginRight: theme.spacing(1),
        maxHeight: 80,
        maxWidth: 80,
        width: 80,
    },
    signature: {
        border: '1px solid grey',
    },
    signatureCanvasContainer: {
        alignItems: 'center',
        border: '1px solid grey',
        display: 'flex',
        flexDirection: 'column',
        height: 150,
        justifyContent: 'center',
        width: 300,
    },
    signatureContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    thanks: {
        marginBottom: theme.spacing(6),
    },
}));

function MyTextField({ data, errors, name, required, setData, setErrors, t, ...other }) {
    return (
        <TextField
            {...other}
            error={!!errors[name]}
            fullWidth
            onBlur={e =>
                setErrors({
                    ...errors,
                    [name]: !!required && e.target.value.length < 1 ? required : false,
                })
            }
            onChange={e => {
                setData({ ...data, [name]: e.target.value });
                setErrors({
                    ...errors,
                    [name]: !!required && e.target.value.length < 1 ? required : false,
                });
            }}
            onFocus={() => setErrors({ ...errors, [name]: false })}
            value={data[name]}
            variant="outlined"
        />
    );
}

const translations = {
    en: {
        aHost: 'host',
        confirm: 'I agree',
        firstname: 'First name',
        host: 'Host',
        lastname: 'Last name',
        next: 'Next',
        organization: 'Organization (optional)',
        required: 'Required',
        reset: 'Reset',
        sign: 'Sign',
        signHere: 'Sign here',
        signIn: 'Log in',
        signOut: 'Log out',
        thanksForRegistering: 'Thanks for registering',
        visited: 'visited',
        visiting: 'is visiting',
    },
    et: {
        aHost: 'võõrustajat',
        confirm: 'Kinnitan',
        firstname: 'Eesnimi',
        host: 'Võõrustaja',
        lastname: 'Perekonnanimi',
        next: 'Edasi',
        organization: 'Organisatsioon (valikuline)',
        required: 'Kohustuslik',
        reset: 'Alusta uuesti',
        sign: 'Allkirjasta',
        signHere: 'Kirjuta siia oma allkiri',
        signIn: 'Logi sisse',
        signOut: 'Logi välja',
        thanksForRegistering: 'Aitäh registreerimast',
        visited: 'külastas',
        visiting: 'külastab',
    },
};

const fields = [
    { autoFocus: true, name: 'firstname', required: true },
    { name: 'lastname', required: true },
    { name: 'organization' },
    { name: 'host', required: true },
];

function downloadCSV(visits) {
    const filename = `cleveron_visits_${new Date().toISOString()}.csv`;
    const csv = visits.reduce(
        (acc, a) =>
            `${acc}\r\n"${new Date(a.timestamp).toISOString()}","${a.lastname}","${a.firstname}","${
                a.organization
            }","${a.host}"`,
        '"timestamp","lastname","firstname","organization","host"'
    );
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

function App() {
    const [errors, setErrors] = useState({});
    const [user, setUser] = useState(false);
    const [visits, setVisits] = useState([]);
    const [lang, setLang] = useState('et');
    const [rulesTimeout, setRulesTimeout] = useState(null);
    const [data, setData] = useState(
        fields.reduce((acc, curr) => ({ ...acc, [curr.name]: '' }), {})
    );
    const history = useHistory();

    useEffect(() => {
        if (user) {
            visitsRef
                .orderByChild('timestamp')
                .on('value', snapshot =>
                    setVisits(
                        Object.values(snapshot.val()).sort((a, b) =>
                            a.timestamp > b.timestamp ? -1 : 1
                        )
                    )
                );
        } else {
            visitsRef.off('value');
        }
        return () => visitsRef.off('value');
    }, [setVisits, user]);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(u => {
            if (u) {
                console.log('logged in user', u);
                setUser(u);
            } else {
                setUser(false);
            }
        });
    }, [setUser]);

    const classes = useStyles();

    function t(key) {
        return translations[lang][key];
    }

    function reset() {
        clearTimeout(rulesTimeout);
        setData(fields.reduce((acc, curr) => ({ ...acc, [curr.name]: '' }), {}));
        setErrors({});
        history.push('/reset');
        setTimeout(() => history.push('/'), 0);
    }

    function goNext() {
        fields.map(field => {
            if (field.required && (!data[field.name] || data[field.name].length < 1)) {
                errors[field.name] = field.required;
            }
            return field;
        });
        setErrors({ ...errors });
        if (Object.values(errors).reduce((acc, val) => acc + !!val, 0) > 0) {
            return;
        }
        window.scrollTo(0, 0);
        history.push('/1');
        setRulesTimeout(setTimeout(reset, 3 * 60 * 1000));
    }

    async function handleSubmit() {
        clearTimeout(rulesTimeout);
        const visitRef = visitsRef.push();
        visitRef.set({
            ...data,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
        });
        history.push('/2');
        window.scrollTo(0, 0);
        setTimeout(() => {
            setData({});
            setLang('et');
            history.push('/');
        }, 2000);
    }

    return (
        <Container className={classes.root} maxWidth="md">
            <Grid container spacing={2}>
                <Grid align="right" className={classes.extendedWidth} item>
                    {user &&
                        (user.email.endsWith('cleveron.com') ||
                            user.email.endsWith('cleveron.eu')) && (
                            <Button component={Link} to="/visits">
                                Visits
                            </Button>
                        )}
                    <Button onClick={() => setLang(lang === 'et' ? 'en' : 'et')}>
                        {lang === 'et' ? 'In English' : 'Eesti keeles'}
                    </Button>
                    <Button onClick={reset}>{t('reset')}</Button>
                </Grid>
                <Grid align="center" item xs={12}>
                    <Link to="/">
                        <img alt="logo" className={classes.logo} src={logo} />
                    </Link>
                </Grid>
                <Switch>
                    <Route exact path="/visits">
                        <Grid align="right" className={classes.extendedWidth} item>
                            <Button onClick={() => downloadCSV(visits)}>Export to CSV</Button>
                        </Grid>
                        <Grid className={classes.extendedWidth} item>
                            <Grid container spacing={2}>
                                {visits.map((visit, i) => {
                                    const visiting =
                                        visit.timestamp + 7 * 24 * 60 * 60 * 1000 > new Date();
                                    const date = new Date(visit.timestamp).toLocaleDateString();
                                    const lastDate =
                                        i > 0
                                            ? new Date(visits[i - 1].timestamp).toLocaleDateString()
                                            : -1;
                                    return (
                                        <React.Fragment
                                            key={visit.firstname + visit.lastname + visit.timestamp}
                                        >
                                            {date !== lastDate && (
                                                <>
                                                    <Typography
                                                        className={classes.dividerFullWidth}
                                                        color="textSecondary"
                                                        display="block"
                                                        variant="caption"
                                                    >
                                                        {date}
                                                    </Typography>
                                                    <Divider className={classes.divider} />
                                                </>
                                            )}
                                            <Grid item sm={6} xs={12}>
                                                <Card className={classes.fillArea}>
                                                    <CardHeader
                                                        avatar={
                                                            <Avatar aria-label={visit.firstname}>
                                                                {visit.firstname[0]}
                                                            </Avatar>
                                                        }
                                                        subheader={new Date(
                                                            visit.timestamp
                                                        ).toLocaleString()}
                                                        title={
                                                            <Typography
                                                                color={
                                                                    visiting
                                                                        ? 'initial'
                                                                        : 'textSecondary'
                                                                }
                                                                variant="body2"
                                                            >
                                                                <strong>
                                                                    {visit.firstname}{' '}
                                                                    {visit.lastname}
                                                                </strong>
                                                                {visit.organization && (
                                                                    <span>
                                                                        {' '}
                                                                        {visit.organization}
                                                                    </span>
                                                                )}
                                                                <span>
                                                                    {' '}
                                                                    {visiting
                                                                        ? t('visiting')
                                                                        : t('visited')}{' '}
                                                                    {t('aHost')}{' '}
                                                                    <strong>{visit.host}</strong>
                                                                </span>
                                                            </Typography>
                                                        }
                                                    />
                                                </Card>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Route>
                    <Route key={`/${lang}`} exact path="/">
                        {fields.map(field => (
                            <Grid key={field.name} item xs={12}>
                                <MyTextField
                                    data={data}
                                    errors={errors}
                                    label={t(field.name)}
                                    setData={setData}
                                    setErrors={setErrors}
                                    t={t}
                                    {...field}
                                />
                            </Grid>
                        ))}
                        <Grid align="center" item xs={12}>
                            <Button
                                className={classes.button}
                                onClick={goNext}
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                {t('next')}
                            </Button>
                        </Grid>
                        <Grid align="right" className={classes.extendedWidth} item>
                            {user ? (
                                <Button onClick={signOut}>{t('signOut')}</Button>
                            ) : (
                                <Button onClick={signIn}>{t('signIn')}</Button>
                            )}
                        </Grid>
                    </Route>
                    <Route exact path="/1">
                        <Grid item xs={12}>
                            <List>
                                <Grid container spacing={2}>
                                    {rules.map(rule => (
                                        <Grid key={rule.image} item sm={6} xs={12}>
                                            <ListItem className={classes.rule}>
                                                <ListItemAvatar>
                                                    <img
                                                        alt={rule[lang]}
                                                        className={classes.ruleImage}
                                                        src={rule.image}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText primary={rule[lang]} />
                                            </ListItem>
                                        </Grid>
                                    ))}
                                </Grid>
                            </List>
                        </Grid>
                        <Grid align="center" item xs={12}>
                            <Button
                                className={classes.button}
                                onClick={handleSubmit}
                                size="large"
                                variant="contained"
                            >
                                {t('confirm')}
                            </Button>
                        </Grid>
                    </Route>
                    <Route exact path="/2">
                        <Grid align="center" item xs={12}>
                            <Typography className={classes.thanks} variant="h4">
                                {t('thanksForRegistering')}, {data.firstname}!
                            </Typography>
                        </Grid>
                    </Route>
                </Switch>
            </Grid>
        </Container>
    );
}

export default App;
